/*------------------------------------
   Breadcrumb
------------------------------------*/
.u-breadcrumb {
	color:var(--#{$prefix}gray);
	background: transparent;
	font-size: 90%;
	a{
		color: var(--#{$prefix}gray);
	}
	.breadcrumb-item {
		font-family: $font_1, $font_2;
		a{
			color: var(--#{$prefix}gray);
		}
	}
	.breadcrumb-item::before {
		display: inline-block;
		padding-right: $breadcrumb-padding-right-1;
		color: var(--#{$prefix}gray);
		content: "»";
	}
	.breadcrumb-item:first-child::before {
		display: none;
	}
}