/*------------------------------------
  Carousel
------------------------------------*/
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: var(--#{$prefix}gray-900)
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button{
  &.previous { left: 10px; }
  &.next { right: 10px; }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  right: auto;
  left: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  left: auto;
  right: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: var(--#{$prefix}gray-900);
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}
.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}
.nav-dark-button {
  .flickity-button {
    background: rgba(197, 198, 208, .7);
    color: var(--#{$prefix}white);
    &:hover {
      background: rgba(197, 198, 208, 1);
    }
  }
}
.nav-primary-button {
  .flickity-button {
    background: var(--#{$prefix}primary) !important;
    color: var(--#{$prefix}white);
    &:hover {
      background: var(--#{$prefix}primary) !important;
      color: $white;
      opacity: .9;
    }
  }
  .flickity-page-dots {
    li {
      background: var(--#{$prefix}primary) !important;
      &.is-selected {
        background: var(--#{$prefix}primary) !important;
      }
    }
  }
}
.nav-inset-button {
  .flickity-button {
    &.previous { 
      left: 1.5rem;
    }
  }
  .flickity-button {
    &.next {
      right: 1.5rem;
    }
  }
  .flickity-page-dots {
    bottom: 1.5rem;
  }
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 33%;
  top: 33%;
  width: 35%;
  height: 35%;
  opacity: .8;
}
.slider-item {
  img {
    cursor: pointer;
  }
}
.dot-in-content {
  .flickity-page-dots {
    margin-bottom: 3rem;
  }
}
.nav-light-dots {
  .flickity-page-dots li {
    background-color: var(--#{$prefix}gray-100);
  }
  .flickity-page-dots li.is-selected {
    background-color: var(--#{$prefix}white);
  }
}
.hero-slider, .hero-slider2 {
  .flickity-button {
    opacity: 0;
    transition: all 1.2s ease;
  }
  &:hover {
    .flickity-button {
      opacity: 1;
      transition: all 1.2s ease;
    }
  }
  .flickity-page-dots {
    bottom: 2rem;
  }
}
.nav-slider-hover, .nav-hover-show {
  .flickity-button {
    opacity: 0;
    transition: all 1.2s ease;
  }
  &:hover {
    .flickity-button {
      opacity: 1;
      transition: all 1.2s ease;
    }
  }
}
.flickity-rtl {
  .reviews {
    &-one, &-two  {
      &::before {
        transform:rotate(180deg);
      }
    }
    &-four, &-five {
      &::after {
        transform:rotate(180deg);
      }
    }
  }
}
.nav-hidden {
  .flickity-button {
    opacity: 0;
  }
}
.nav-dots-top-right {
  .flickity-page-dots {
    bottom: auto;
    top: 1rem;
    right: 1rem;
    text-align: right;
  }
}
.light-dots {
  .dot {
    background: $light;
    opacity: .75;
    &.is-selected {
      background: var(--#{$prefix}primary);
    }
  }
}

.box-carousel {
  .flickity-button-icon {
    width: 20%;
  }
  .flickity-button {
    background: transparent;
    &:hover {
      background: transparent;
    }
    &.previous {
      left: auto;
      right: 2rem;
    }
    &.next {
      right: 0;
    }
  }
}