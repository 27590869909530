/*------------------------------------
	Vertical tabs
------------------------------------*/
.vertical-tabs {
	background: var(--#{$prefix}light);
	.nav {
		&.nav-tabs {
			float: left;
			display: block;
			border-bottom: 0;
			margin-right: $vertical-navtabs-right;
			border-right: $border-color $vertical-navtabs-border-right solid;
		}
	}
	.nav-tabs {
		.nav-link {
			border: $vertical-navlink-border solid transparent;
			border-top-left-radius: $vertical-navlink-radius !important;
			border-top-right-radius: $vertical-navlink-radius !important;
		}
	}
	.tab-content {
		&> .active {
			display: block;
			min-height: $vertical-content-height;
		}
	}
	ul {
		li {
			margin-right: $vertical-li-margin-right;
		}
	}
}
.navbar-nav {
	.vertical-tabs {
		.nav-tabs {
			.nav-link {
				&.active {
					color: var(--#{$prefix}primary);
					border-color: $vertical-navlink-border-color;
					background-color: var(--#{$prefix}white);
				}
			}
		}
	}
}
.navbar-dark {
	.vertical-tabs {
		background: var(--#{$prefix}dark);
		border-color: var(--#{$prefix}dark);
    }
}

/*------------------------------------
	Sidebar tabs
------------------------------------*/
//@extend-elements
//original selectors
//.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
%extend_sidetabs {
	color: $sidetabs-color;
	background-color: var(--#{$prefix}primary);
	border-color: var(--#{$prefix}primary);
}
.nav-tabs {
	.nav-link {
		&.active {
			@extend %extend_sidetabs;
			&:hover {
				border-color: var(--#{$prefix}primary);
			}
			&:focus {
				border-color: var(--#{$prefix}primary);
			}
		}
	}
	.nav-item.show {
		.nav-link {
			@extend %extend_sidetabs;
			&:hover, &:focus {
				border-color: $nav-tabs-border-hover;
			}
		}
	}
}
.vertical-tabs {
	.nav-tabs {
		.nav-link {
			&:hover {
				border-color: $nav-tabs-border-hover;
			}
		}
	}
}