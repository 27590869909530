/*------------------------------------
  Sidebar
------------------------------------*/
.start-sidebar {
	padding-right: $sidebar-padding-right;
}
.end-sidebar {
	padding-left: $sidebar-padding-left;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		.start-sidebar#{$infix} {
			padding-right: $sidebar-padding-right;
			&::after {
				margin-top: 5rem;
				position: relative;
				content: "";
				padding: 2rem;
				display: block;
			}
		}
		.end-sidebar#{$infix} {
			padding-left: $sidebar-padding-left;
			&::after {
				margin-top: 5rem;
				position: relative;
				content: "";
				padding: 2rem;
				display: block;
			}
		}
	}
}
.sidebar-start .side-link {
	li {
		a {
			color: var(--#{$prefix}text-dark-light);
		}
	}
}
// Sidelink color
.side-link, .sidebar-link {
	li {
		a {
			&.caret-collapse{
				color: var(--#{$prefix}primary);
			}
			&.caret-collapse.collapsed{
				color: var(--#{$prefix}dark-light);
				&:hover{
					color: var(--#{$prefix}primary);
				}
			}
			&:hover, &.active{
				background-color: transparent;
				color: var(--#{$prefix}primary);
			}
		}
		ul {
			li{
				a {
					color: var(--#{$prefix}dark-light);
					&:hover{
						color: var(--#{$prefix}primary);
					}
				}
			}
		}
	}
}
.sidebar-link {
	li {
		a {
			display: block;
			font-size: $sidebar-link-font-size;
			padding: $sidebar-link-padding;
		}
	}
}
.mobile-nav-custom img {
    max-width: 200px
}
@media (min-width: 992px) {
   .nav-custom.navbar-dark .hover-mode li:hover > a {
      background: var(--#{$prefix}white-black) !important;
   }
  .mobile-nav-custom, .mobile-nav-custom img {
      max-width: 0;
      display: none;
  }
}

@media (min-width: 992px) {
    .logo-full .main-logo img {
        max-width: 25rem;
    }
    .search-top-right, .social-top-left {
        top: 50%;
        margin-top: -23px;
        position: absolute;
    }
    .showbacktop .logo-showbacktop.still-show {
        display: block;
    }
    .showbacktop .logo-showbacktop.still-show img {
        max-height: 2.3rem;
        margin-right: 1rem;
        display: block;
    }
    .showbacktop.is-visible .logo-showbacktop.still-show {
        display: none;
        height: 0;
    }
}