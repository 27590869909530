/*------------------------------------
	Color
------------------------------------*/
a {
	color: var(--#{$prefix}link-color);
}
h1 , h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: var(--#{$prefix}body-color);
	> a {
		color: var(--#{$prefix}body-color);
		-webkit-font-smoothing: antialiased;
	}
	> .btn {
		a{
			color: var(--#{$prefix}white);
			&:hover {
				color: var(--#{$prefix}white);
			}
		}
	}
}
p {
	color: var(--#{$prefix}gray-700);
}
.form-check-input:checked, .form-check-input[type=checkbox]:indeterminate {
	background-color:  var(--#{$prefix}primary);
	border-color:  var(--#{$prefix}primary);
}
.form-range::-webkit-slider-thumb, .form-range::-moz-range-thumb {
	background-color:  var(--#{$prefix}primary);
}
/*------------------------------------
  Extra color
------------------------------------*/
@each $color, $value in $morecolors {
	.bg-#{$color} {
		background-color: var(--#{$prefix}#{$color}) !important;
	}
	.text-#{$color} {
		color: var(--#{$prefix}#{$color}) !important;
	}
	.border-#{$color} {
		border-color: var(--#{$prefix}#{$color}) !important;
	}
}
.bg-themes {
	background: var(--#{$prefix}white) !important;
}
.bg-light-dark, .bg-light-black {
	background: var(--#{$prefix}light) !important;
}
.black {
	a{
		color: var(--#{$prefix}black);
	}
}
.white {
	a {
		color: var(--#{$prefix}white);
	}
}
.filter-image {
	filter: var(--bs-filter-image);
}
.input-group-text {
	color: var(--bs-body-color);
	background-color: var(--bs-light-black);
	border-color: var(--bs-border-color);
}