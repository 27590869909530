/*------------------------------------
	Tags
------------------------------------*/
.post-content {
	.tags-list { 
		li {
			padding: $tags-list-padding;
			display: inline-block;
			border: var(--#{$prefix}border-color) 1px solid;
			margin-bottom: $tags-list-bottom;
			margin-left: inherit;
			line-height: inherit;
			&:hover {
				background-color: var(--#{$prefix}primary);
				a {
					color: var(--#{$prefix}white);
				}
			}
			&:first-child {
				padding-left: 0;
				font-family: $font_1, $font_2;
				background-color: transparent;
				border-color: transparent !important;
				font-weight: $font-weight-500;
				span {
					color: var(--#{$prefix}gray-900);
				}
			}
		}
	}
}
// tags in post
.tags-links {
	a {
		padding: $tags-list-padding;
		display: inline-block;
		border: var(--#{$prefix}border-color) 1px solid;
		margin-right: $tags-list-right;
		margin-bottom: $tags-list-bottom;
		margin-left: inherit;
		line-height: inherit;
		&:hover {
			background-color: var(--#{$prefix}primary);
			color: var(--#{$prefix}white);
		}
	}
}
// tags in category
.tags-list-cat {
	li {
		padding: $tags-list-padding;
		display: inline-block;
		border: var(--#{$prefix}border-color) 1px solid;
		margin-bottom: $tags-list-bottom;
		&:hover {
			background-color: var(--#{$prefix}primary);
			a {
				color: var(--#{$prefix}white);
			}
		}
	}
}
.tagcloud {
	a {
		&:before {
			content: "#";
		}
	}
}